<template>
  <div class="practical-info-widget">
    <WidgetsPracticalInfoWidgetHeading
      :title="widget.title"
      :subtitle="widget.description"
      :image="widget.image"
      :h1="isFirst"
    >
      <div
        v-if="!widget.hide_subsections_links"
        class="table-of-contents-wrapper"
      >
        <div class="dot" />
        <ul class="table-of-contents md-up">
          <WidgetsPracticalInfoWidgetSubsectionLink
            v-for="{ title, id } in widget.subsections"
            :key="id"
            :title="title"
            :hash="toHash(id)"
            :active="activeSubsectionId === id"
          />
        </ul>
      </div>
    </WidgetsPracticalInfoWidgetHeading>
    <CollapseGroup
      :id="`subsections-${widget.id}`"
      tag="div"
      class="subsections"
    >
      <WidgetsPracticalInfoWidgetSubsection
        v-for="subsection in widget.subsections"
        :id="`collapse-subsection-${subsection.id}`"
        ref="subsections"
        :key="subsection.id"
        :title="subsection.title"
        :collapsible-on-mobile="widget.collapsible_on_mobile"
        :active="isMounted && $route.hash === toHash(subsection.id)"
        :class="widget.subsections.length === 1 && 'full-height'"
      >
        <Component
          :is="sections[subsection.type]"
          :id="`subsection-${subsection.id}`"
          :section="subsection"
        />
      </WidgetsPracticalInfoWidgetSubsection>
    </CollapseGroup>
  </div>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import { throttle } from 'lodash-es'

import CommuneSubsection from '@/components/global/widgets/PracticalInfoWidget/sections/Commute.vue'
import ContactSubsection from '@/components/global/widgets/PracticalInfoWidget/sections/Contact.vue'
import OpeningHoursSubsection from '@/components/global/widgets/PracticalInfoWidget/sections/OpeningHours.vue'
import PricingSubsection from '@/components/global/widgets/PracticalInfoWidget/sections/Pricing.vue'
import TextSubsection from '@/components/global/widgets/PracticalInfoWidget/sections/Text.vue'
import type {
  PublicPracticalInfoWidget,
  Type175Enum
} from '~/service/__generated-api'
import type { Widget } from '~/types/utils'

const props = defineProps<Widget<PublicPracticalInfoWidget>>()

const sections: Record<Type175Enum, Component> = {
  CommuneSubsection,
  ContactSubsection,
  OpeningHoursSubsection,
  PricingSubsection,
  TextSubsection
}

const isMounted = useIsMounted()
const toHash = (id: string) => `#subsection-${id}`

const activeSubsectionId = ref(props.widget.subsections[0]?.id)
const activeItemNumber = ref(1)

const setActiveSubsectionIndex = () => {
  const subsections = [
    ...document.querySelectorAll<HTMLElement>(
      `#widget-${props.widget.id} [id^="subsection-"]`
    )
  ]

  const activeIndex = subsections
    .map(element => {
      const { top, bottom, height } = element.getBoundingClientRect()
      const bottomOffset = window.innerHeight - bottom

      let partVisible = height
      if (top < 0) partVisible = Math.max(partVisible + top, 0)
      if (bottomOffset < 0)
        partVisible = Math.max(partVisible + bottomOffset, 0)

      return partVisible / height
    })
    .reduce(
      // eslint-disable-next-line max-params
      (maxIndex, currentValue, index, array) =>
        currentValue > array[maxIndex] ? index : maxIndex,
      0
    )

  activeItemNumber.value = activeIndex + 1
  activeSubsectionId.value =
    props.widget.subsections[activeIndex]?.id ?? props.widget.subsections[0]?.id
}

onMounted(() => {
  useEventListener('scroll', throttle(setActiveSubsectionIndex, 200))
})
</script>

<style lang="scss" scoped>
.practical-info-widget {
  position: relative;
  background-color: #0b0d1a;

  @include media-up(md) {
    display: flex;
  }
}

.table-of-contents {
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  gap: var(--items-gap);
  color: $beige;

  .contrast-mode & {
    color: $contrast-yellow;
    background-color: $contrast-black;
  }
}

.subsections {
  flex-grow: 1;
}

.table-of-contents-wrapper {
  --active-item: v-bind(activeItemNumber);
  --items-gap: #{rem(27px)};
  --padding-top: #{rem(32px)};
  @include hide-down(md);
  position: relative;
  grid-column: 2;
  padding: var(--padding-top) rem(53px) rem(38px);
  background-color: $blue;

  @include media-up(lg) {
    --items-gap: #{rem(28px)};
    --padding-top: #{rem(54px)};
    height: fit-content;
    padding: var(--padding-top) rem(40px) rem(51px);
  }

  @include media-up(xl) {
    --items-gap: #{rem(44px)};
    padding-bottom: rem(90px);
  }
}

.dot {
  --dot-size: #{rem(7px)};
  @include size(var(--dot-size));
  position: absolute;
  top: calc(
    var(--padding-top) + (var(--item-height) / 2 - var(--dot-size) / 2) +
      (var(--active-item) - 1) * (var(--items-gap) + var(--item-height))
  );
  background-color: $white-hex;
  border-radius: rem(3.5px);
  transition: top $transition;

  @include media-up(md) {
    --item-height: #{rem(22px)};
  }

  @include media-up(lg) {
    --item-height: #{rem(25px)};
  }

  @include media-up(xl) {
    --item-height: #{rem(32px)};
  }
}

.full-height {
  height: 100%;
}
</style>
